import fourZerofour from "@images/404@2x.png";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
type Props = { statusCode: any };

function Error(props: Props) {
    const router = useRouter();
    const { locale } = router;
    let { statusCode } = props;

    return (
        <div>
            <div className="w-full flex justify-center py-[150px] bg-[#ffffff]">
                <div className="left flex items-center">
                    <Image
                        lazyBoundary="10px"
                        alt="404"
                        src={fourZerofour}
                        width={222}
                        height={191}
                    ></Image>
                </div>
                <div className="right ml-[62px] lg1024:ml-[6px]">
                    <div
                        className="text-[48px] font-[600] text-[#2E384E] "
                        style={{
                            fontFamily: "PingFangSC-Semibold, PingFang SC",
                        }}
                    >
                        {statusCode}
                    </div>
                    <div
                        className="mt-[6px] text-[20px] font-[500] text-[#708096]"
                        style={{ fontFamily: "PingFangSC-Medium, PingFang SC" }}
                    >
                        {locale === "en"
                            ? "Ooops! Page Not Found~"
                            : "噢！页面找不到"}
                    </div>
                    <Link href={`/${locale}`}>
                        <div
                            className="mt-[30px] w-[112px] h-[40px] rounded-[4px] bg-[#FF3E5F] flex items-center justify-center text-[16px] font-[500] text-[#FFFFFF]"
                            style={{
                                fontFamily: "PingFangSC-Medium, PingFang SC",
                            }}
                        >
                            {locale === "en" ? "Go Back" : "返回首页"}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

// Error.getInitialProps = async (props: any) => {
//     let { res, err, locale }: { res: any; err: any; locale: any } = props;
//     const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
//     return {
//         // props: {
//         //     ...(await serverSideTranslations(locale!, [
//         //         "home",
//         //         "common",
//         //         "nav",
//         //         "footer",
//         //     ])),
//         // },

//         statusCode,
//     };
// };

export async function getStaticProps({
    locale,
    res,
    err,
}: {
    locale: any;
    res: any;
    err: any;
}) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return {
        props: {
            statusCode,
            ...(await serverSideTranslations(locale, [
                "common",
                "footer",
                "nav",
            ])), // Will be passed to the page component as props
        },
    };
}
export default Error;
